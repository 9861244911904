<template>
  <div>
    <v-row align="center" justify="center">
      <v-col class="col-banner">
        <v-img src="@/assets/ecommerce.jpg" alt="Banner"></v-img>
      </v-col>
    </v-row>

    <v-row class="ma-5" align="center" justify="center">
      <v-col cols="10">
        <h1 class="center blue--text pa-3">COMERCIO ELECTRÓNICO</h1>
        Plataforma de gestión de tiendas en línea, segura y muy fácil de usar,
        administrador de contenido, pagina web adaptable a cualquier dispositivo
        y aplicación móvil en Android o iOS, y todo con tu propia marca.
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <v-card min-height="210">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-list-alt</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">
              Categorías y Subcategorías
            </h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Organiza tus productos por categorías y subcategorías.
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <v-card min-height="210">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-dolly-flatbed</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">Gestiona tu Inventario</h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Gestiona la cantidad de productos disponibles a la venta.
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <v-card min-height="210">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-info</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">Opciones Detalladas</h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Puedes detallar tu producto al máximo, agregarle opciones, fecha de
            disponibilidad, tamaños, colores, peso, fabricantes, filtros,
            ofertas
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <v-card min-height="210">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-trophy</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">
              Sistema de Puntos por Compras
            </h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Tus clientes pueden recibir puntos por las compras realizadas en tu
            plataforma.
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <v-card min-height="210">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-blog</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">
              Blog
            </h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Con un sistema de blog incorporado te permitirá interactuar con tus
            clientes, compartiendo experiencias, novedades o noticias.
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <v-card min-height="210">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-hands-helping</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">
              Interacción con tu Cliente
            </h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Los clientes podrán comentar tus productos, calificarlos, y recibir
            descuentos, además de hacer sus listas y comparar.
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <v-card min-height="210">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-gifts</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">
              Cupones y Vales de Regalo
            </h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Crea cupones de descuento o vales de regalo para para tus clientes.
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <v-card min-height="210">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-donate</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">
              Promociones
            </h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Publica banners publicitarios, promociones, descuentos en tu página.
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <v-card min-height="210">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-envelope</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">
              Notificaciones Email o Móviles
            </h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Envía notificaciones o email a dispositivos móviles de promociones o
            novedades en tu página.
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <v-card min-height="210">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-money-bill-alt</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">
              Pagos
            </h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Se pueden escoger el método de pago de conveniencia del cliente,
            entre esos la transferencia bancaria, pago contra entrega, o
            integración con payu, mercadopago, etc.
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <v-card min-height="210">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-cogs</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">
              Pedidos e Impuestos
            </h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Al realizar el pedido el cliente se puede estimar los impuestos y el
            costo del envío.
          </v-card-subtitle>
        </v-card>
      </v-col>

      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <v-card min-height="210">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-users</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">
              Registro de Clientes
            </h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Tus clientes se registran, y empiezan a ordenar.
          </v-card-subtitle>
        </v-card>
      </v-col>

      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <v-card min-height="210">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-file-invoice-dollar</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">
              Informes
            </h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Saca tus informes de pedidos, productos, clientes que más compraron
            y muchos más reportes.
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <v-card min-height="210">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-receipt</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">
              Impresión de factura y lista de pedido
            </h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Impresión desde el administrador de facturas y listas de pedidos.
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <v-card min-height="210">
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-truck-loading</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">
              Gestiona tus Pedidos
            </h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Se te notificara cuando un cliente hace un pedido a tu plataforma,
            además se le enviara un email con la información detallada del
            pedido al cliente
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Ecommerce",
  components: {},
  metaInfo() {
    return {
      title: "Fénix Software - Comercio Electrónico"
    };
  },
  data() {
    return {};
  }
};
</script>
